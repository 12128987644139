import React from "react";

const Policy = () => {
	return (
		<>
			<div className="back-to-top">
				<a href="#top">
					<i class="bi bi-arrow-up-circle-fill"></i>
				</a>
			</div>
			<div className="container" id="top">
				<div className="corporate-governance-policies">
					<div className="main-heading text-center">
						<img src="/images/coins/fx-u-logo.png" alt="" />
						<p>Last Updated: February 1, 2022</p>
						<h1>CORPORATE GOVERNANCE POLICIES</h1>
					</div>
					<div className="table-of-contents">
						<h3>TABLE OF CONTENTS</h3>
						<h5><a rel="noreferrer" href="#introduction"><span>1.</span>	Introduction</a></h5>
						<ul>
							<li><a rel="noreferrer" href="#11"><span>1.1</span> Nature and of UMU CORPORATION's Purpose Corporate Governance Policies</a></li>
							<li><a rel="noreferrer" href="#12"><span>1.2</span> Federal and State Law Compliance</a></li>
							<li><a rel="noreferrer" href="#13"><span>1.3</span> Disclosed Governance Policies and Ethics Code</a></li>
							<li><a rel="noreferrer" href="#14"><span>1.4</span> Accountability to Shareowners</a></li>
							<li><a rel="noreferrer" href="#15"><span>1.5</span> Shareowner Participation</a></li>
							<li><a rel="noreferrer" href="#16"><span>1.6</span> Business Practices and Corporate Citizenship</a></li>
							<li><a rel="noreferrer" href="#17"><span>1.7</span> Governance Practices at Public and Private Companies</a></li>
							<li><a rel="noreferrer" href="#18"><span>1.8</span> Reincorporation</a></li>
							<li><a rel="noreferrer" href="#19"><span>1.9</span> Judicial Forum</a></li>
						</ul>
						<h5><a rel="noreferrer" href="#BoardofDirectors"><span>2.</span> The Board of Directors</a></h5>
						<ul>
							<li><a rel="noreferrer" href="#21"><span>2.1</span> Annual Election of Directors</a></li>
							<li><a rel="noreferrer" href="#22"><span>2.2</span> Director Elections</a></li>
							<li><a rel="noreferrer" href="#23"><span>2.3</span> Independent Board</a></li>
							<li><a rel="noreferrer" href="#24"><span>2.4</span> Independent Chair/Lead Director</a></li>
							<li><a rel="noreferrer" href="#25"><span>2.5</span> All-independent Board Committees</a></li>
							<li><a rel="noreferrer" href="#26"><span>2.6</span> Board Accountability to Shareowners</a></li>
							<li><a rel="noreferrer" href="#27"><span>2.7</span> Board's Role in Risk Oversight</a></li>
							<li><a rel="noreferrer" href="#28"><span>2.8</span> Board/Director Succession Planning and Evaluation</a></li>
							<li><a rel="noreferrer" href="#29"><span>2.9</span> CEO Succession Planning</a></li>
							<li><a rel="noreferrer" href="#210"><span>2.10</span> "Continuing Directors"</a></li>
							<li><a rel="noreferrer" href="#211"><span>2.11</span> Board Size and Service</a></li>
							<li><a rel="noreferrer" href="#212"><span>2.12</span> Board Operations</a></li>
							<li><a rel="noreferrer" href="#213"><span>2.13</span> Auditor Independence</a></li>
							<li><a rel="noreferrer" href="#214"><span>2.14</span> Charitable and Political Contributions</a></li>
							<li><a rel="noreferrer" href="#215"><span>2.15</span> Directors with Conflicts</a></li>
						</ul>
						<h5><a rel="noreferrer" href="#ShareownerVotingRights"><span>3.</span> Shareowner Voting Rights</a></h5>
						<ul>
							<li><a rel="noreferrer" href="#31"><span>3.1</span> Right to Vote is Inviolate</a></li>
							<li><a rel="noreferrer" href="#32"><span>3.2</span> Access to the Pro</a></li>
							<li><a rel="noreferrer" href="#33"><span>3.3</span> One Share, One Vote</a></li>
							<li><a rel="noreferrer" href="#34"><span>3.4</span> Advance Notice, Holding Requirements and Other Provisions</a></li>
							<li><a rel="noreferrer" href="#35"><span>3.5</span> Confidential Voting</a></li>
							<li><a rel="noreferrer" href="#36"><span>3.6</span> Voting Requirements</a></li>
							<li><a rel="noreferrer" href="#37"><span>3.7</span> Broker Votes</a></li>
							<li><a rel="noreferrer" href="#38"><span>3.8</span> Bundled Voting</a></li>
						</ul>
						<h5><a rel="noreferrer" href="#ShareownerMeetings"><span>4.</span> Shareowner Meetings</a></h5>
						<ul>
							<li><a rel="noreferrer" href="#41"><span>4.1</span> Selection and Notification of Meeting Time and Location</a></li>
							<li><a rel="noreferrer" href="#42"><span>4.2</span> Shareowner Rights to Call Special Meetings</a></li>
							<li><a rel="noreferrer" href="#43"><span>4.3</span> Record Date and Ballot Item Disclosure</a></li>
							<li><a rel="noreferrer" href="#44"><span>4.4</span> Timely Disclosure of Voting Results</a></li>
							<li><a rel="noreferrer" href="#45"><span>4.5</span> Election Polls</a></li>
							<li><a rel="noreferrer" href="#46"><span>4.6</span> Meeting Adjournment and Extension</a></li>
							<li><a rel="noreferrer" href="#47"><span>4.7</span> Electronic Meetings</a></li>
							<li><a rel="noreferrer" href="#48"><span>4.8</span> Director Attendance</a></li>
						</ul>
						<h5><a rel="noreferrer" href="#ExecutiveCompensation"><span>5.</span> Executive Compensation</a></h5>
						<ul>
							<li><a rel="noreferrer" href="#51"><span>5.1</span>  Introduction</a></li>
							<li><a rel="noreferrer" href="#52"><span>5.2</span>  Advisory Shareowner Votes on Executive Pay</a></li>
							<li><a rel="noreferrer" href="#53"><span>5.3</span>  Gross-ups</a></li>
							<li><a rel="noreferrer" href="#54"><span>5.4</span>  Shareowner Approval of Equity-based Compensation Plans</a></li>
							<li><a rel="noreferrer" href="#55"><span>5.5</span>  Role of Compensation Committee</a></li>
							<li><a rel="noreferrer" href="#56"><span>5.6</span>  Salary</a></li>
							<li><a rel="noreferrer" href="#57"><span>5.7</span>  Annual Incentive Compensation</a></li>
							<li><a rel="noreferrer" href="#58"><span>5.8</span>  Long-term Incentive Compensation</a></li>
							<li><a rel="noreferrer" href="#59"><span>5.9</span>  Dilution</a></li>
							<li><a rel="noreferrer" href="#510"><span>5.10</span> Stock Option Awards</a></li>
							<li><a rel="noreferrer" href="#511"><span>5.11</span> Stock Awards/Units</a></li>
							<li><a rel="noreferrer" href="#512"><span>5.12</span> Perquisites</a></li>
							<li><a rel="noreferrer" href="#513"><span>5.13</span> Employment Contracts, Severance and Change-of-control Payments</a></li>
							<li><a rel="noreferrer" href="#514"><span>5.14</span> Retirement Arrangements</a></li>
							<li><a rel="noreferrer" href="#515"><span>5.15</span> Stock Ownership</a></li>
						</ul>
						<h5><a rel="noreferrer" href="#DirectorCompensation"><span>6.</span> Director Compensation</a></h5>
						<ul>
							<li><a rel="noreferrer" href="#61"><span>6.1</span>  Introduction</a></li>
							<li><a rel="noreferrer" href="#62"><span>6.2</span>  Role of the Compensation Committee in Director Compensation</a></li>
							<li><a rel="noreferrer" href="#63"><span>6.3</span>  Retainer</a></li>
							<li><a rel="noreferrer" href="#64"><span>6.4</span>  Equity-based Compensation</a></li>
							<li><a rel="noreferrer" href="#65"><span>6.5</span>  Performance-based Compensation</a></li>
							<li><a rel="noreferrer" href="#66"><span>6.6</span>  Perquisites</a></li>
							<li><a rel="noreferrer" href="#67"><span>6.7</span>  Repricing and Exchange Programs</a></li>
							<li><a rel="noreferrer" href="#68"><span>6.8</span>  Employment Contracts, Severance and Change-of-control Payments</a></li>
							<li><a rel="noreferrer" href="#69"><span>6.9</span>  Retirement</a></li>
							<li><a rel="noreferrer" href="#610"><span>6.10</span> Disgorgement</a></li>
						</ul>
						<h5><a rel="noreferrer" href="#IndependentDirector"><span>7.</span> Independent Director Definition</a></h5>
						<ul className="mb-0">
							<li><a rel="noreferrer" href="#71"><span>7.1</span>  Introduction</a></li>
							<li><a rel="noreferrer" href="#72"><span>7.2</span>  Basic Definition of an Independent Director</a></li>
							<li><a rel="noreferrer" href="#73"><span>7.3</span>  Guidelines for Assessing Director Independence</a></li>
						</ul>
					</div>
					<div id="introduction" className="introduction mt-5">
						<h3>1. Introduction</h3>
						<ul>
							<li><a rel="noreferrer" href="#11"><span>1.1</span> Nature and of UMU CORPORATION's Purpose Corporate Governance Policies</a></li>
							<li><a rel="noreferrer" href="#12"><span>1.2</span> Federal and State Law Compliance</a></li>
							<li><a rel="noreferrer" href="#13"><span>1.3</span> Disclosed Governance Policies and Ethics Code</a></li>
							<li><a rel="noreferrer" href="#14"><span>1.4</span> Accountability to Shareowners</a></li>
							<li><a rel="noreferrer" href="#15"><span>1.5</span> Shareowner Participation</a></li>
							<li><a rel="noreferrer" href="#16"><span>1.6</span> Business Practices and Corporate Citizenship</a></li>
							<li><a rel="noreferrer" href="#17"><span>1.7</span> Governance Practices at Public and Private Companies</a></li>
							<li><a rel="noreferrer" href="#18"><span>1.8</span> Reincorporation</a></li>
							<li><a rel="noreferrer" href="#19"><span>1.9</span> Judicial Forum</a></li>
						</ul>
						<div className="introduction-content">
							<h6 id="11">1.1 Nature and Purpose of UMU CORPORATION's Corporate Governance Policies: </h6>
							<p>UMU policies are designed to provide guidelines that UMU has found to be appropriate in most situations. UMU reserves the right to modify or make exception any corporate governance policy at any time as it deems appropriate for operating the business with the highest degree of standards, excellence, and ethics and for protecting the interests of its investors and shareholders.</p>
							<h6 id="12">1.2	Federal and State Law Compliance: </h6>
							<p>UMU expects that the corporation and each of its operating subsidiaries ("Companies") will comply with all applicable federal and state laws and regulations and stock exchange listing standards.</p>
							<h6 id="13">1.3	Disclosed Governance Policies and Ethics Code:</h6>
							<p>UMU believes every company should have written, disclosed governance procedures and policies, an ethics code that applies to all employees and directors, and provisions for its strict enforcement. UMU posts its corporate governance policies on its Web site (www.UMU); it expect all operating companies to meet or exceed these standards and adopt similarly appropriate additional policies to best protect shareowners' interests.</p>
							<h6 id="14">1.4	Accountability to Shareowners:</h6>
							<p>Corporate governance structures and practices should protect and enhance a company's accountability to its shareowners, and ensure that they are treated equally. An action should not be taken if its purpose is to reduce accountability to shareowners.</p>
							<h6 id="15">1.5	Shareowner Participation: </h6>
							<p>Shareowners should have meaningful ability to participate in the major fundamental decisions that affect corporate viability, and meaningful opportunities to suggest or nominate director candidates and to suggest processes and criteria for director selection and evaluation.</p>
							<h6 id="16">1.6	Business Practices and Corporate Citizenship: </h6>
							<p>UMU believes our companies should adhere to responsible business practices and practice good corporate citizenship. Promotion, adoption and effective implementation of guidelines for the responsible conduct of business and business relationships are consistent with the fiduciary responsibility of protecting long-term investment interests.</p>
							<h6 id="17">1.7	Governance Practices at Public Companies: </h6>
							<p>As a publicly traded company, we should adopt long-term corporate governance provisions that are consistent with UMU's policies.</p>
							<h6 id="18">1.8	Reincorporation:</h6>
							<p>Notwithstanding a local operating and business presence, UMU should not reincorporate to offshore locations where corporate governance structures are weaker, which reduces management accountability to shareowners.</p>
							<h6 id="19">1.9	Judicial Forum:</h6>
							<p className="mb-0">Companies should not attempt to restrict the venue for shareowner claims by adopting charter or bylaw provisions that seek to establish an exclusive forum. Nor should companies attempt to bar shareowners from the courts through the introduction of forced arbitration clauses.</p>
						</div>
					</div>
					<div id="BoardofDirectors" className="introduction mt-5">
						<h3>2.	The Board of Directors</h3>
						<ul>
							<li><a rel="noreferrer" href="#21"><span>2.1</span> Annual Election of Directors</a></li>
							<li><a rel="noreferrer" href="#22"><span>2.2</span> Director Elections</a></li>
							<li><a rel="noreferrer" href="#23"><span>2.3</span> Independent Board</a></li>
							<li><a rel="noreferrer" href="#24"><span>2.4</span> Independent Chair/Lead Director</a></li>
							<li><a rel="noreferrer" href="#25"><span>2.5</span> All-independent Board Committees</a></li>
							<li><a rel="noreferrer" href="#26"><span>2.6</span> Board Accountability to Shareowners</a></li>
							<li><a rel="noreferrer" href="#27"><span>2.7</span> Board's Role in Risk Oversight</a></li>
							<li><a rel="noreferrer" href="#28"><span>2.8</span> Board/Director Succession Planning and Evaluation</a></li>
							<li><a rel="noreferrer" href="#29"><span>2.9</span> CEO Succession Planning</a></li>
							<li><a rel="noreferrer" href="#210"><span>2.10</span> "Continuing Directors"</a></li>
							<li><a rel="noreferrer" href="#211"><span>2.11</span> Board Size and Service</a></li>
							<li><a rel="noreferrer" href="#212"><span>2.12</span> Board Operations</a></li>
							<li><a rel="noreferrer" href="#213"><span>2.13</span> Auditor Independence</a></li>
							<li><a rel="noreferrer" href="#214"><span>2.14</span> Charitable and Political Contributions</a></li>
							<li><a rel="noreferrer" href="#215"><span>2.15</span> Directors with Conflicts</a></li>
						</ul>
						<div className="introduction-content annual-election">
							<h6 id="21">2.1 Annual Election of Directors</h6>
							<p>All directors should be elected annually. Boards should not be classified (staggered).</p>
							<h6 id="22">2.2 Director Elections</h6>
							<p>Directors in uncontested elections should be elected by a majority of the votes cast. In contested elections, plurality voting should apply. An election is contested when there are more director candidates than there are available board seats. To facilitate the shareholder voting franchise, the opposing sides engaged in a contested election should utilize a proxy card naming all management- nominees and all shareholder-proponent nominees, providing every nominee equal prominence on the proxy card.</p>
							<p>Directors who fail to receive the support of a majority of votes cast in an uncontested election should step down from the board and not be reappointed. A modest transition period may be appropriate under certain circumstances, such as for directors keeping the company in compliance with legal or listing standards. But any director who does not receive the majority of votes cast should leave the board as soon as practicable.</p>
							<h6 id="23">2.3 Independent Board</h6>
							<p>At least two-thirds of the directors should be independent; their seat on the board should be their only non-trivial professional, familial or financial connection to the corporation, its chairman, CEO or any other executive officer. The company should disclose information necessary for shareowners to determine whether directors qualify as independent. This information should include all of the company's financial or business relationships with and payments to directors and their families and all significant payments to companies, non-profits, foundations and other organizations where company directors serve as employees, officers or directors (see UMU definition of independent director, Section 7, below).</p>
							<h6 id="24">2.4 Independent Chair/Lead Director</h6>
							<p>The board should be chaired by an independent director. The CEO and chair roles should only be combined in very limited circumstances; in these situations, the board should provide a written statement in the proxy materials discussing why the combined role is in the best interests of shareowners, and it should name a lead independent director who should have approval over information flow to the board, meeting agendas and meeting schedules to ensure a structure that provides an appropriate balance between the powers of the CEO and those of the independent directors.</p>
							<p>Other roles of the lead independent director should include chairing meetings of non-management directors and of independent directors, presiding over board meetings in the absence of the chair, serving as the principle liaison between the independent directors and the chair and leading the board/director evaluation process. Given these additional responsibilities, the lead independent director should expect to devote a greater amount of time to board service than the other directors.</p>
							<h6 id="25">2.5 All-independent Board Committees</h6>
							<p>Companies should have audit, nominating and compensation committees, and all members of these committees should be independent. The board (not the CEO) should appoint the committee chairs and members. Committees should be able to select their own service providers. Some regularly scheduled committee meetings should be held with only the committee members (and, if appropriate, the committee's independent consultants) present. The process by which committee members and chairs are selected should be disclosed to shareowners.</p>
							<h6 id="26">2.6 Board Accountability to Shareowners</h6>
							<ul>
								<li>
									<h6>2.6a Majority Shareowner Votes</h6>
									<p>Boards should take actions recommended in shareowner proposals that receive a majority of votes cast for and against. If shareowner approval is required for the action, the board should seek a binding vote on the action at the next shareowner meeting.</p>
								</li>
								<li>
									<h6>2.6b	Interaction with Shareowners: </h6>
									<p>Directors should respond to communications from shareowners and should seek shareowner views on important governance, management and performance matters. To accomplish this goal, all companies should establish board-shareowner communications policies. Such policies should disclose the ground rules by which directors will meet with shareowners. The policies should also include detailed contact information for at least one independent director (but preferably for the independent board chair and/or the independent lead director and the independent chairs of the audit, compensation and nominating committees). Companies should also establish mechanisms by which shareowners with non-trivial concerns can communicate directly with all directors. Policies requiring that all director communication go through a member of the management team should be avoided unless they are for record-keeping purposes. In such cases, procedures documenting receipt and delivery of the request to the board and its response must be maintained and made available to shareowners upon request. Directors should have access to all communications. Boards should determine whether outside counsel should be present at meetings with shareowners to monitor compliance with disclosure rules.</p>
									<p>All directors should attend the annual shareowners' meetings and be available, when requested by the chair, to answer shareowner questions. During the annual general meeting, shareowners should have the right to ask questions, both orally and in writing. Directors should provide answers or discuss the matters raised, regardless of whether the questions were submitted in advance.
										While reasonable time limits for questions are acceptable, the board should not ignore a question because it comes from a shareowner who holds a smaller number of shares or who has not held those shares for a certain length of time.</p>
								</li>
							</ul>
							<h6 id="27">2.7 Board's Role in Risk Oversight</h6>
							<p>The board has ultimate responsibility for risk oversight. The board should (1) establish a company's risk management philosophy and risk appetite; (2) understand and ensure risk management practices for the company; (3) regularly review risks in relation to the risk appetite;
								and (4) evaluate how management responds to the most significant risks. In determining the risk profile, the board should consider the dynamics of the company, its industry and any systemic risks. UMU policies on other critical corporate governance matters, such as executive compensation (see 5.1, UMU's policy on executive compensation, below), reinforce the importance of the board's consideration of risk factors.
								Effective risk oversight requires regular, meaningful communication between the board and management, among board members and committees, and between the board and any outside advisers it consults, about the company's material risks and risk management processes. The board should disclose to shareowners, at least annually, sufficient information to enable them to assess whether the board is carrying out its oversight responsibilities effectively.</p>
							<h6 id="28">2.8 Board/Director Succession Planning and Evaluation</h6>
							<ul>
								<li>
									<h6>2.8a	Board Succession Planning: </h6>
									<p>The board should implement and disclose a board succession plan that involves preparing for future board retirements, committee assignment rotations, committee chair nominations and overall implementation of the company's long-term business plan. Boards should establish clear procedures to encourage and consider board nomination suggestions from long-term shareowners. The board should respond positively to shareowner requests seeking to discuss incumbent and potential directors.</p>
								</li>
								<li>
									<h6>2.8b	Board Diversity: </h6>
									<p>UMU supports a diverse board. UMU believes a diverse board has benefits that can enhance corporate financial performance, particularly in today's global market place. Nominating committee charters, or equivalent, ought to reflect that boards should be diverse, including such considerations as background, experience, age, race, gender, ethnicity, and culture.</p>
								</li>
								<li>
									<h6>2.8c	Evaluation of Directors: </h6>
									<p>Boards should review their own performance periodically. That evaluation should include a review of the performance and qualifications of any director who received "against" votes from a significant number of shareowners or for whom a significant number of shareowners withheld votes.</p>
								</li>
								<li>
									<h6>2.8d	Board and Committee Meeting Attendance: </h6>
									<p>Absent compelling and stated reasons, directors who attend fewer than 75 percent of board and board-committee meetings for two consecutive years should not be renominated. Companies should disclose individual director attendance figures for board and committee meetings. Disclosure should distinguish between in- person and telephonic attendance. Excused absences should not be categorized as attendance.</p>
								</li>
							</ul>
							<h6 id="29">2.9 CEO Succession Planning</h6>
							<p>The board should approve and maintain a detailed CEO succession plan and publicly disclose the essential features in the proxy statement. An integral facet of management succession planning involves collaboration between the board and the current chief executive to develop the next generation of leaders from within the company's ranks. Boards therefore should: (1) make sure that broad leadership development programs are in place generally; and (2) carefully identify multiple candidates for the CEO role specifically, well before the position needs to be filled. To that end, the plan should address both short and long-term succession scenarios.</p>
							<h6 id="210">2.10 "Continuing Directors"</h6>
							<p>Corporations should not adopt so-called "continuing director" provisions (also known as "dead-hand" or "no-hand" provisions, which are most commonly seen in connection with a potential change in control of the company) that allow board actions to be taken only by: (1) those continuing directors who were also in office when a specified event took place or (2) a combination of continuing directors plus new directors who are approved by such continuing directors.</p>
							<h6 id="211">2.11 Board Size and Service</h6>
							<p>Absent compelling, unusual circumstances, a board should have no fewer than five and no more than 15 members (not too small to maintain the needed expertise and
								independence, and not too large to function efficiently). Shareowners should be allowed to vote on any major change in board size.
							</p>
							<p>Companies should establish and publish guidelines specifying on how many other boards their directors may serve. Absent unusual, specified circumstances, directors with full-time jobs should not serve on more than two other boards. Currently serving CEOs should not serve as a director of more than one other company, and then only if the CEO's own company is in the top half of its peer group. No other director should serve on more than five for-profit company boards.</p>
							<h6 id="212">2.12 Board Operations</h6>
							<ul>
								<li>
									<h6>2.12a	Informed Directors: </h6>
									<p>Directors should receive training from independent sources on their fiduciary responsibilities and liabilities. Directors have an affirmative obligation to become and remain independently familiar with company operations; they should not rely exclusively on information provided to them by the CEO to do their jobs. Directors should be provided meaningful information in a timely manner prior to board meetings and should be allowed reasonable access to management to discuss board issues. The board should periodically assess whether directors feel they have sufficient information to make well-informed decisions and reasonable access to management on matters relevant to shareowner value. For ease of implementation, such assessment may be incorporated into existing director surveys.</p>
								</li>
								<li>
									<h6>2.12b	Director Rights Regarding Board Agenda: </h6>
									<p>Any director should be allowed to place items on the board's agenda.</p>
								</li>
								<li>
									<h6>2.12c	Executive Sessions:</h6>
									<p>The independent directors should hold regularly scheduled executive sessions without any of the management team or its staff present.</p>
								</li>
							</ul>
							<h6 id="213">2.13 Auditor Independence</h6>
							<ul>
								<li>
									<h6>2.13a	Audit Committee Responsibilities Regarding Independent Auditors: </h6>
									<p>The audit committee should fully exercise its authority to hire, compensate, oversee and, if necessary, terminate the company's independent auditor. In doing so, the committee should take proactive steps to promote auditor independence and audit quality. Even in the absence of egregious reasons, the committee should consider the appropriateness of periodically changing the auditor, bearing in mind factors that include, but are not limited to:</p>
									<ul>
										<li>
											<p>the auditor's tenure as independent auditor of the company</p>
										</li>
										<li>
											<p>the presence of former audit partners, managers or senior officers in financial reporting or executive positions at the company, or former financial executives of the company in lead offices performing audit work on the company</p>
										</li>
										<li>
											<p>directors' relationships with the auditor, including through directors' employer and service on other audit committees</p>
										</li>
										<li>
											<p>the proportion of total fees attributable to non-audit services, and a determination of why these services could not have been provided by another party to safeguard the auditor's independence</p>
										</li>
										<li>
											<p>the completeness, timeliness and clarity of the annual letter to the audit committee discussing the independence of the auditor</p>
										</li>
										<li>
											<p>the significance of the audit and total fees to the lead office and engagement partner performing the independent audit</p>
										</li>
										<li>
											<p>the quality and frequency of communication from the auditor to the audit committee</p>
										</li>
										<li>
											<p>the experience, expertise and professional skepticism of the audit partner, manager and senior personnel assigned to the audit, and the extent of their involvement in performing the audit</p>
										</li>
										<li>
											<p>the incidence and circumstances surrounding a financial restatement, whether at the company or at another company audited by the same firm</p>
										</li>
										<li>
											<p>the incidence and circumstances surrounding the reporting of a material weakness in internal controls by the auditor</p>
										</li>
										<li>
											<p>the clarity, utility and insights provided in the auditor's report and the auditor's letter to management in relation to the audit</p>
										</li>
										<li>
											<p>the level of transparency and robustness of the audit firm with the audit committee and investors, including with respect to audit quality indicators, governance practices and underlying principles, and the financial stability of the audit firm</p>
										</li>
										<li>
											<p>inspection results and fines levied by the Public Company Accounting Oversight Board or other regulators</p>
										</li>
										<li>
											<p>the track record of the lead partners and the extent of their professional commitments, as provided upon request or observable through disclosure or signature of the lead partner on the auditor's report</p>
										</li>
										<li>
											<p>reasons cited by other companies for discontinuing their engagement of the same audit partner and/or auditor</p>
										</li>
										<li>
											<p>the results of annual auditor performance reviews by audit committee members</p>
										</li>
										<li>
											<p>the availability of a replacement for the existing auditor with the requisite experience and staffing required by professional standards to perform a quality audit</p>
										</li>
										<li>
											<p>the auditor's position on whether it requires the inclusion of an arbitration clause that would place limitations on investors' ability to recover damages they have incurred</p>
										</li>
									</ul>
									<p>Investors are the "customers" and end users of financial statements and disclosures in the public capital markets. Both the audit committee and the auditor should recognize this principle.</p>
									<p>The audit committee report should provide meaningful information to investors about how the committee carries out its responsibilities. The report should include an explanation of how the committee carries out its auditor compensation responsibilities in consideration of audit quality objectives. The report should include a fact specific explanation for not changing the company's auditor if the committee chooses to renew the engagement of an auditor with more than 10 consecutive years of service, or if the auditor is retained despite knowledge of substantive deficiencies identified during the committee's review of the considerations described above.</p>
								</li>
								<li>
									<h6>2.13b	Competitive Bids:</h6>
									<p>The audit committee should seek competitive bids for the external audit engagement at least every five years.</p>
								</li>
								<li>
									<h6>2.13c Non-audit Services: </h6>
									<p>A company's external auditor should not perform any non-audit services for the company, except those, such as attest services, that are required by statute or regulation to be performed by a company's external auditor.</p>
								</li>
								<li>
									<h6>2.13d Audit Committee Charters: </h6>
									<p>The proxy statement should include a copy of the audit committee charter and a statement by the audit committee that it has complied with the duties outlined in the charter.</p>
								</li>
								<li>
									<h6>2.13e	Liability of Outside Auditors: </h6>
									<p>Companies should not agree to limit the liability of outside auditors.</p>
								</li>
								<li>
									<h6>2.13f	Shareowner Votes on the Board's Choice of Outside Auditor:</h6>
									<p>Audit committee charters should provide for annual shareowner votes on the board's choice of independent,
										external auditor. Such provisions should state that if the board's selection fails to achieve the support of a majority of the for-and-against votes cast, the audit committee should: (1) take the shareowners' views into consideration and reconsider its choice of auditor and (2) solicit the views of major shareowners to determine why broad levels of shareowner support were not achieved.
									</p>
								</li>
								<li>
									<h6>2.13g	Disclosure of Reasons Behind Auditor Changes:</h6>
									<p>The audit committee should publicly provide to shareowners a plain-English explanation of the reasons for a change in the company's external auditors. At a minimum, this disclosure should be contained in the same Securities and Exchange Commission (SEC) filing that companies are required to submit within four days of an auditor change.</p>
								</li>
							</ul>
							<h6 id="214">2.14 Charitable and Political Contributions</h6>
							<ul>
								<li>
									<h6>2.14a	Board Monitoring, Assessment and Approval: </h6>
									<p>The board of directors should monitor, assess and approve all charitable and political contributions (including trade association contributions) made by the company. The board should only approve contributions that are consistent with the interests of the company and its shareowners. The terms and conditions of such contributions should be clearly defined and approved by the board.</p>
								</li>
								<li>
									<h6>2.14b	Disclosure: </h6>
									<p>The board should develop and disclose publicly its guidelines for approving charitable and political contributions. The board should disclose on an annual basis the amounts and recipients of all monetary and non-monetary contributions made by the company during the prior fiscal year. Any expenditures earmarked for political or charitable activities that were provided to or through a third-party should be included in the report.</p>
								</li>
							</ul>
							<h6 id="215">2.15 Directors with Conflicts</h6>
							<p>A director with a conflict of interest in a matter before the board should immediately communicate all facts about the conflict and abstain from voting on the matter.</p>
							<p>Deliberation on the matter should take place only among non-conflicted directors. The content of the deliberations, both verbal and written, should not be shared with the conflicted director.</p>
							<p className="mb-0">Prior to deliberation, the non-conflicted directors should have discretion to invite the conflicted director to share information that could help inform the vote. The conflicted director should comply if such communication is not prohibited by contract or law.</p>
						</div>
					</div>
					<div id="ShareownerVotingRights" className="introduction mt-5">
						<h3>3.	Shareowner Voting Rights</h3>
						<ul>
							<li><a rel="noreferrer" href="#31"><span>3.1</span> Right to Vote is Inviolate</a></li>
							<li><a rel="noreferrer" href="#32"><span>3.2</span> Access to the Pro</a></li>
							<li><a rel="noreferrer" href="#33"><span>3.3</span> One Share, One Vote</a></li>
							<li><a rel="noreferrer" href="#34"><span>3.4</span> Advance Notice, Holding Requirements and Other Provisions</a></li>
							<li><a rel="noreferrer" href="#35"><span>3.5</span> Confidential Voting</a></li>
							<li><a rel="noreferrer" href="#36"><span>3.6</span> Voting Requirements</a></li>
							<li><a rel="noreferrer" href="#37"><span>3.7</span> Broker Votes</a></li>
							<li><a rel="noreferrer" href="#38"><span>3.8</span> Bundled Voting</a></li>
						</ul>
						<div className="introduction-content annual-election">
							<h6 id="31">3.1 Right to Vote is Inviolate:</h6>
							<p>A shareowners' right to vote is inviolate and should not be abridged.</p>
							<h6 id="32">3.2 Access to the Proxy:</h6>
							<p>Companies should provide access to management proxy materials for a long-term investor or group of long-term investors owning in aggregate at least three percent of a company's voting stock, to nominate less than a majority of the directors. Eligible investors must have owned the stock for at least two years. Company proxy materials and related mailings should provide equal space and equal treatment of nominations by qualifying investors.</p>
							<p>To allow for informed voting decisions, it is essential that investors have full and accurate information about access mechanism users and their director nominees. Therefore, shareowners nominating director candidates under an access mechanism should adhere to the same SEC rules governing disclosure requirements and prohibitions on false and misleading statements that currently apply to proxy contests for board seats.</p>
							<h6 id="33">3.3	Voting Rights: </h6>
							<p>Each share of common stock should have one vote. Corporations should not have classes of common stock with disparate voting rights. Authorized, unissued preferred shares that have voting rights to be set by the board should not be issued without shareowner approval.</p>
							<h6 id="34">3.4 Advance Notice, Holding Requirements and Other Provisions:</h6>
							<p>Advance notice bylaws, holding requirements, disclosure rules and any other company imposed regulations on the ability of shareowners to solicit proxies beyond those required by law should not be so onerous as to deny sufficient time, limit the pool of eligible candidates, or otherwise make it impractical for shareowners to submit nominations or proposals and distribute supporting proxy materials.</p>
							<h6 id="35">3.5 Confidential Voting:</h6>
							<p>All proxy votes should be confidential, with ballots counted by independent tabulators. Confidentiality should be automatic, permanent and apply to all ballot items. Rules and practices concerning the casting, counting and verifying of shareowner votes should be clearly disclosed.</p>
							<h6 id="36">3.6 Voting Requirements:</h6>
							<p> A majority vote of common shares outstanding should be sufficient to amend company bylaws or take other action that requires or receives a shareowner vote. Supermajority votes should not be required. A majority vote of common shares outstanding should be required to approve:</p>
							<ul className="p-0">
								<li>
									<ul>
										<li>
											<p>Major corporate decisions concerning the sale or pledge of corporate assets that would have a material effect on shareowner value. Such a transaction will automatically be deemed to have a material effect if the value of the assets exceeds 10 percent of the assets of the company and its subsidiaries on a consolidated basis;</p>
										</li>
										<li>
											<p>The corporation's acquisition of five percent or more of its common shares at above-market prices other than by tender offer to all shareowners;</p>
										</li>
										<li>
											<p>Poison pills;</p>
										</li>
										<li>
											<p>Abridging or limiting the rights of common shares to: (1) vote on the election or removal of directors or the timing or length of their term of office or (2) nominate directors or propose other action to be voted on by shareowners or (3) call special meetings of shareowners or take action by written consent or change the procedure for fixing the record date for such action; and</p>
										</li>
										<li>
											<p>Issuing debt to a degree that would excessively leverage the company and imperil its long-term viability.</p>
										</li>
									</ul>
								</li>
							</ul>
							<h6 id="37">3.7 Broker Votes:</h6>
							<p>Uninstructed broker votes and abstentions should be counted only for purposes of a quorum.</p>
							<h6 id="38">3.8 Bundled Voting:</h6>
							<p className="mb-0">Shareowners should be allowed to vote on unrelated issues separately. Individual voting issues (particularly those amending a company's charter), bylaws or anti-takeover provisions should not be bundled.</p>
						</div>
					</div>
					<div id="ShareownerMeetings" className="introduction mt-5">
						<h3>4.	Shareowner Meetings</h3>
						<ul>
							<li><a rel="noreferrer" href="#41"><span>4.1</span> Selection and Notification of Meeting Time and Location</a></li>
							<li><a rel="noreferrer" href="#42"><span>4.2</span> Shareowner Rights to Call Special Meetings</a></li>
							<li><a rel="noreferrer" href="#43"><span>4.3</span> Record Date and Ballot Item Disclosure</a></li>
							<li><a rel="noreferrer" href="#44"><span>4.4</span> Timely Disclosure of Voting Results</a></li>
							<li><a rel="noreferrer" href="#45"><span>4.5</span> Election Polls</a></li>
							<li><a rel="noreferrer" href="#46"><span>4.6</span> Meeting Adjournment and Extension</a></li>
							<li><a rel="noreferrer" href="#47"><span>4.7</span> Electronic Meetings</a></li>
							<li><a rel="noreferrer" href="#48"><span>4.8</span> Director Attendance</a></li>
						</ul>
						<div className="introduction-content annual-election">
							<h6 id="41">4.1 Selection and Notification of Meeting Time and Location:</h6>
							<p>Corporations should make shareowners' expense and convenience primary criteria when selecting the time and location of shareowner meetings. Appropriate notice of shareowner meetings, including notice concerning any change in meeting date, time, place or shareowner action, should be given to shareowners in a manner and within time frames that will ensure that shareowners have a reasonable opportunity to exercise their franchise.</p>
							<h6 id="42">4.2 Shareowner Rights to Call Special Meetings:</h6>
							<p>Shareowners should have the right to call special meetings.</p>
							<h6 id="43">4.3 Record Date and Ballot Item Disclosure:</h6>
							<p>To promote the ability of shareowners to make informed decisions regarding whether to recall loaned shares: (1) shareowner meeting record dates should be disclosed as far in advance of the record date as possible, and (2) proxy statements should be disclosed before the record date passes whenever possible.</p>
							<h6 id="44">4.4 Timely Disclosure of Voting Results:</h6>
							<p>A company should broadly and publicly disclose in a timely manner the final results of votes cast at annual and special meetings of shareowners. Whenever possible, preliminary results should be announced at the annual or special meeting of shareowners.</p>
							<h6 id="45">4.5 Election Polls:</h6>
							<p>Polls should remain open at shareowner meetings until all agenda items have been discussed and shareowners have had an opportunity to ask and receive answers to questions concerning them.</p>
							<h6 id="46">4.6 Meeting Adjournment and Extension:</h6>
							<p>Companies should not adjourn a meeting for the purpose of soliciting more votes to enable management to prevail on a voting item. A meeting should only be extended for compelling reasons such as vote fraud, problems with the voting process or lack of a quorum.</p>
							<h6 id="47">4.7 Electronic Meetings:</h6>
							<p>Companies should hold shareowner virtual meetings by remote communication only as a supplement to traditional in-person shareowner meetings, not as a substitute.</p>
							<p>Companies incorporating virtual technology into their shareowner meeting should use it as a tool for broadening, not limiting, shareowner meeting participation. With this objective in mind, a virtual option, if used, should facilitate the opportunity for remote attendees to participate in the meeting to the same degree as in-person attendees.</p>
							<h6 id="48">4.8 Director Attendance:</h6>
							<p>As noted in Section 2, "The Board of Directors," all directors should attend the annual shareowners' meeting and be available, when requested by the chair, to respond directly to oral or written questions from shareowners.</p>
						</div>
					</div>
					<div id="ExecutiveCompensation" className="introduction mt-5">
						<h3>5.	Executive Compensation</h3>
						<ul>
							<li><a rel="noreferrer" href="#51"><span>5.1</span>  Introduction</a></li>
							<li><a rel="noreferrer" href="#52"><span>5.2</span>  Advisory Shareowner Votes on Executive Pay</a></li>
							<li><a rel="noreferrer" href="#53"><span>5.3</span>  Gross-ups</a></li>
							<li><a rel="noreferrer" href="#54"><span>5.4</span>  Shareowner Approval of Equity-based Compensation Plans</a></li>
							<li><a rel="noreferrer" href="#55"><span>5.5</span>  Role of Compensation Committee</a></li>
							<li><a rel="noreferrer" href="#56"><span>5.6</span>  Salary</a></li>
							<li><a rel="noreferrer" href="#57"><span>5.7</span>  Annual Incentive Compensation</a></li>
							<li><a rel="noreferrer" href="#58"><span>5.8</span>  Long-term Incentive Compensation</a></li>
							<li><a rel="noreferrer" href="#59"><span>5.9</span>  Dilution</a></li>
							<li><a rel="noreferrer" href="#510"><span>5.10</span> Stock Option Awards</a></li>
							<li><a rel="noreferrer" href="#511"><span>5.11</span> Stock Awards/Units</a></li>
							<li><a rel="noreferrer" href="#512"><span>5.12</span> Perquisites</a></li>
							<li><a rel="noreferrer" href="#513"><span>5.13</span> Employment Contracts, Severance and Change-of-control Payments</a></li>
							<li><a rel="noreferrer" href="#514"><span>5.14</span> Retirement Arrangements</a></li>
							<li><a rel="noreferrer" href="#515"><span>5.15</span> Stock Ownership</a></li>
						</ul>
						<div className="introduction-content annual-election">
							<h6 id="51">5.1 Introduction:</h6>
							<p>UMU believes that executive compensation is a critical and visible aspect of a company's governance. Pay decisions are one of the most direct ways for shareowners to assess the performance of the board. And they have a bottom line effect, not just in terms of dollar amounts, but also by formalizing performance goals for employees, signaling the market and affecting employee morale.</p>
							<p>UMU endorses reasonable, appropriately structured pay-for-performance programs that reward executives for sustainable, superior performance over the long-term, consistent with a company's investment horizon. "Long-term" is generally considered to be five or more years for mature companies and at least three years for other companies. While UMU believes that executives should be well paid for superior performance, it also believes that executives should not be excessively paid. It is the job of the board of directors and the compensation committee specifically to ensure that executive compensation programs are effective, reasonable and rational with respect to critical factors such as company performance, industry considerations, risk considerations and compensation paid to other employees.</p>
							<p>It is also the job of the compensation committee to ensure that elements of compensation packages are appropriately structured to enhance the company's short- and long-term strategic goals and to retain and motivate executives to achieve those strategic goals. Compensation programs should not be driven by competitive surveys, which have become excessive and subject to abuse. It is shareowners, not executives, whose money is at risk.</p>
							<p>Since executive compensation must be tailored to meet unique company needs and situations, compensation programs must always be structured on a company-by-company basis. However, certain principles should apply to all companies.</p>
							<h6 id="52">5.2 Advisory Shareowner Votes on Executive Pay:</h6>
							<p>All companies should provide annually for advisory shareowner votes on the compensation of senior executives.</p>
							<h6 id="53">5.3 Gross-ups:</h6>
							<p>Senior executives should not receive gross-ups beyond those provided to all the company's employees.</p>
							<h6 id="54">5.4 Shareowner Approval of Equity-based Compensation Plans:</h6>
							<p>Current listing standards require shareowner approval of equity-based compensation plans and material amendments to plans (with limited exceptions). UMU strongly supports this concept and advocates that companies adopt conservative interpretations of approval requirements when confronted with choices. (For example, this may include material amendments to the plan.)</p>
							<h6 id="55">5.5 Role of Compensation Committee:</h6>
							<p>The compensation committee is responsible for structuring executive pay and evaluating executive performance within the context of the pay structure of the entire company, subject to approval of the board of directors. To best handle this role, compensation committees should adopt the following principles and practices:</p>
							<ul>
								<li>
									<h6>5.5a	Committee Composition:</h6>
									<p>All members of the compensation committee should be independent. Committee membership should rotate periodically among the board's independent directors. Members should be or take responsibility to become knowledgeable about compensation and related issues. They should exercise due diligence and independent judgment in carrying out their committee responsibilities. They should represent diverse backgrounds and professional experiences.</p>
								</li>
								<li>
									<h6>5.5b	Executive Pay Philosophy: </h6>
									<p>The compensation philosophy should be clearly disclosed to shareowners in annual proxy statements. In developing, approving and monitoring the executive pay philosophy, the compensation committee should consider the full range of pay components, including structure of programs, desired mix of cash and equity awards, goals for distribution of awards throughout the company, the relationship of executive pay to the pay of other employees, use of employment contracts and policy regarding dilution.</p>
								</li>
								<li>
									<h6>5.5c	Oversight: </h6>
									<p>The compensation committee should vigorously oversee all aspects of executive compensation for a group composed of the CEO and other highly paid executives, as required by law, and any other highly paid employees, including executives of subsidiaries, special purpose entities and other affiliates, as determined by the compensation committee. The committee should ensure that the structure of employee compensation throughout the company is fair, non- discriminatory and forward-looking, and that it motivates, recruits and retains a workforce capable  of meeting the company's strategic objectives. To perform its oversight duties, the committee
										should approve, comply with and fully disclose a charter detailing its responsibilities.</p>
								</li>
								<li>
									<h6>5.5d	Pay for Performance: </h6>
									<p>Compensation of the executive oversight group should be driven predominantly by performance. The compensation committee should establish performance measures for executive compensation that are agreed to ahead of time and publicly disclosed.
										Multiple performance measures should be used in an executive's incentive program, and the measures should be sufficiently diverse that they do not simply reward the executive multiple times for the same performance. The measures should be aligned with the company's short- and long- term strategic goals, and pay should incorporate company-wide performance metrics, not just business unit performance criteria.</p>
									<p>Performance measures applicable to all performance-based awards (including annual and long- term incentive compensation) should reward superior performance-based predominantly on measures that drive long-term value creation-at minimum reasonable cost. Such measures should also reflect downside risk. The compensation committee should ensure that key performance metrics cannot be manipulated easily.</p>
									<p>The compensation committee should ensure that sufficient and appropriate mechanisms and policies (for example, bonus banks and clawback policies) are in place to recover erroneous bonus and incentive awards paid in cash, stock or any other form of remuneration to current or former executive officers, and to prevent such awards from being paid out in the first instance. Awards can be erroneous due to acts or omissions resulting in fraud, financial results that require restatement or some other cause that the committee believes warrants withholding or recovering incentive pay. Incentive-based compensation should be subject to recovery for a period of time of at least three years following discovery of the fraud or cause forming the basis for the recovery. The mechanisms and policies should be publicly disclosed.</p>
								</li>
								<li>
									<h6>5.5e	Annual Approval and Review:</h6>
									<p>Each year, the compensation committee should review performance of individuals in the oversight group and approve any bonus, severance, equity-based award or extraordinary payment made to them. The committee should understand all components of executive compensation and annually review total compensation potentially payable to the oversight group under all possible scenarios, including death/disability, retirement, voluntary termination, termination with and without cause and changes of control. The committee should also ensure that the structure of pay at different levels (CEO and others in the oversight group, other executives and non-executive employees) is fair and appropriate in the context of broader company policies and goals and fully justified and explained.</p>
								</li>
								<li>
									<h6>5.5f	Committee Accountability: </h6>
									<p>In addition to attending all annual and special shareowner meetings, committee members should be available to respond directly to questions about executive compensation; the chair of the committee should take the lead. In addition, the committee should regularly report on its activities to the independent directors of the board, who should review and ratify committee decisions. Committee members should take an active role in preparing the compensation committee report contained in the annual proxy materials, and be responsible for the contents of that report.</p>
								</li>
								<li>
									<h6>5.5g	Outside Advice: </h6>
									<p>The compensation committee should retain and fire outside experts, including consultants, legal advisers and any other advisers when it deems appropriate, including when negotiating contracts with executives. Individual compensation advisers and their firms should be independent of the client company, its executives and directors and should report solely to the compensation committee. The compensation committee should develop and disclose a formal policy on compensation adviser independence. In addition, the committee should annually disclose an assessment of its advisers' independence, along with a description of the nature and dollar amounts of services commissioned from the advisers and their firms by the client company's management. Companies should not agree to indemnify or limit the liability of compensation advisers or the advisers' firms.</p>
								</li>
								<li>
									<h6>5.5h	Disclosure Practices: </h6>
									<p>The compensation committee is responsible for ensuring that all aspects of executive compensation are clearly, comprehensively and promptly disclosed, in plain English, in the annual proxy statement regardless of whether such disclosure is required by current rules and regulations. The compensation committee should disclose all information necessary for shareowners to understand how and how much executives are paid and how such pay fits within the overall pay structure of the company. It should provide annual proxy statement disclosure of the committee's compensation decisions with respect to salary, short-term incentive compensation, long-term incentive compensation and all other aspects of executive compensation, including the relative weights assigned to each component of total compensation.</p>
									<p>The compensation committee should commit to provide full descriptions of the qualitative and quantitative performance measures and benchmarks used to determine compensation, including the weightings and rationale for each measure. At the beginning of a period, the compensation
										committee should calculate and disclose the maximum compensation payable if all performance- related targets are met. At the end of the performance cycle, the compensation committee should disclose actual targets and details on final payouts. Companies should provide forward-looking disclosure of performance targets whenever possible. Other recommended disclosures relevant to specific elements of executive compensation are detailed below.
									</p>
								</li>
								<li>
									<h6>5.5i	Benchmarking:</h6>
									<p>Benchmarking at median or higher levels is a primary contributor to escalating executive compensation. Although benchmarking can be a constructive tool for formulating executive compensation packages, it should not be relied on exclusively. If benchmarking is used, compensation committees should commit to annual disclosure of the companies in peer groups used for benchmarking and/or other comparisons. If the peer group used for compensation purposes differs from that used to compare overall performance, such as the five- year stock return graph required in the annual proxy materials, the compensation committee should describe the differences between the groups and the rationale for choosing between them. In addition to disclosing names of companies used for benchmarking and comparisons, the compensation committee should disclose targets for each compensation element relative to the peer/benchmarking group and year-to-year changes in companies composing peer/benchmark groups.</p>
								</li>
							</ul>
							<h6 id="56">5.6 Salary:</h6>
							<ul>
								<li>
									<h6>5.6a	Salary Level: </h6>
									<p>Since salary is one of the few components of executive compensation that is not "at risk," it should be set at a level that yields the highest value for the company at least cost. In general, salary should be set to reflect responsibilities, tenure and past performance, and to be tax efficient-meaning no more than $1 million.</p>
								</li>
								<li>
									<h6>5.6b	Above-median Salary: </h6>
									<p>The compensation committee should publicly disclose its rationale for paying salaries above the median of the peer group.</p>
								</li>
							</ul>
							<h6 id="57">5.7 Annual Incentive Compensation:</h6>
							<p>Cash incentive compensation plans should be structured to align executive interests with company goals and objectives. They should also reasonably reward superior performance that meets or exceeds well-defined and clearly disclosed performance targets that reinforce long-term strategic goals that were written and approved by the board in advance of the performance cycle.</p>
							<ul>
								<li>
									<h6>5.7a	Formula Plans:</h6>
									<p>The compensation committee should approve formulaic bonus plans containing specific qualitative and quantitative performance-based operational measures designed to reward executives for superior performance related to operational/strategic/other goals set by the board. Such awards should be capped at a reasonable maximum level. These caps should not be calculated as percentages of accounting or other financial measures (such as revenue, operating income or net profit), since these figures may change dramatically due to mergers, acquisitions and other non-performance-related strategic or accounting decisions.</p>
								</li>
								<li>
									<h6>5.7b	Targets: </h6>
									<p>When setting performance goals for "target" bonuses, the compensation committee should set performance levels below which no bonuses would be paid and above which bonuses would be capped.</p>
								</li>
								<li>
									<h6>5.7c	Changing Targets: </h6>
									<p>Except in extraordinary situations, the compensation committee should not "lower the bar" by changing performance targets in the middle of bonus cycles. If the committee decides that changes in performance targets are warranted in the middle of a performance cycle, it should disclose the reasons for the change and details of the initial targets and adjusted targets.</p>
								</li>
							</ul>
							<h6 id="58">5.8 Long-term Incentive Compensation:</h6>
							<p>Long-term incentive compensation, generally in the form of equity-based awards, can be structured to achieve a variety of long-term objectives, including retaining executives, aligning executives' financial interests with the interests of shareowners and rewarding the achievement of long-term specified strategic goals of the company and/or the superior performance of company stock.</p>
							<p>But poorly structured awards permit excessive or abusive pay that is detrimental to the company and to shareowners. To maximize effectiveness and efficiency, compensation committees should carefully evaluate the costs and benefits of long-term incentive compensation, ensure that long-term compensation is appropriately structured and consider whether performance and incentive objectives would be enhanced if awards were distributed throughout the company, not simply to top executives.</p>
							<p>Companies may rely on a myriad of long-term incentive vehicles to achieve a variety of long-term objectives, including performance-based restricted stock/units, phantom shares, stock units and stock options. While the technical underpinnings of long-term incentive awards may differ, the following principles and practices apply to all long-term incentive compensation awards. And, as detailed below, certain policies are relevant to specific types of long-term incentive awards.</p>
							<ul>
								<li>
									<h6>5.8a	Size of Awards: </h6>
									<p>Compensation committees should set appropriate limits on the size of long-term incentive awards granted to executives. So-called "mega-awards" or outsized awards should be avoided, except in extraordinary circumstances, because they can be disproportionate to performance.</p>
								</li>
								<li>
									<h6>5.8b	Vesting Requirements: </h6>
									<p>All long-term incentive awards should have meaningful performance periods and/or cliff vesting requirements that are consistent with the company's investment horizon but not less than three years, followed by pro rata vesting over at least two subsequent years for senior executives.</p>
								</li>
								<li>
									<h6>5.8c	Grant Timing: </h6>
									<p>Except in extraordinary circumstances, such as a permanent change in performance cycles, long-term incentive awards should be granted at the same time each year. Companies should not coordinate stock award grants with the release of material non-public information. The grants should occur whether recently publicized information is positive or negative, and stock options should never be backdated.</p>
								</li>
								<li>
									<h6>5.8d	Hedging: </h6>
									<p>Compensation committees should prohibit executives and directors from hedging (by buying puts and selling calls or employing other risk-minimizing techniques) equity- based awards granted as long-term incentive compensation or other stock holdings in the company. And they should strongly discourage other employees from hedging their holdings in company stock.</p>
								</li>
								<li>
									<h6>5.8e	Philosophy/Strategy: </h6>
									<p>Compensation committees should have a well-articulated philosophy and strategy for long-term incentive compensation that is fully and clearly disclosed in the annual proxy statement.</p>
								</li>
								<li>
									<h6>5.8f	Award Specifics: </h6>
									<p>Compensation committees should disclose the size, distribution, vesting requirements, other performance criteria and grant timing of each type of long-term incentive award granted to the executive oversight group. Compensation committees also should explain how each component contributes to the company's long-term performance objectives.</p>
								</li>
								<li>
									<h6>5.8g	Ownership Targets: </h6>
									<p>Compensation committees should disclose whether and how long- term incentive compensation may be used to satisfy meaningful stock ownership requirements.
										Disclosure should include any post-exercise holding periods or other requirements to ensure that long-term incentive compensation is used appropriately to meet ownership targets.
									</p>
								</li>
								<li>
									<h6>5.8h	Expiration Dates: </h6>
									<p>Compensation plans should have expiration dates and not be structured as "evergreen," rolling plans.</p>
								</li>
							</ul>
							<h6 id="59">5.9 Dilution:</h6>
							<p>Dilution measures how much the additional issuance of stock may reduce existing shareowners' stake in a company. Dilution is particularly relevant for long-term incentive compensation plans since these programs essentially issue stock at below-market prices to the recipients. The potential dilution represented by long-term incentive compensation plans is a direct cost to shareowners.</p>
							<p>Dilution from long-term incentive compensation plans may be evaluated using a variety of techniques including the reduction in earnings per share and voting power resulting from the increase in outstanding shares.</p>
							<ul>
								<li>
									<h6>5.9a	Philosophy/Strategy: </h6>
									<p>Compensation committees should develop and disclose the philosophy regarding dilution including definition(s) of dilution, peer group comparisons and specific targets for annual awards and total potential dilution represented by equity compensation programs for the current year and expected for the subsequent four years.</p>
								</li>
								<li>
									<h6>5.9b	Stock Repurchase Programs: </h6>
									<p>Stock buyback decisions are a capital allocation decision and should not be driven solely for the purpose of minimizing dilution from equity-based compensation plans. The compensation committee should provide information about stock repurchase programs and the extent to which such programs are used to minimize the dilution of equity-based compensation plans.</p>
								</li>
								<li>
									<h6>5.9c	Tabular Disclosure: </h6>
									<p>The annual proxy statement should include a table detailing the overhang represented by unexercised options and shares available for award and a discussion of the impact of the awards on earnings per share.</p>
								</li>
							</ul>
							<h6 id="510">5.10 Stock Option Awards:</h6>
							<p>Stock options give holders the right, but not the obligation, to buy stock in the future. Options may be structured in a variety of ways. Some structures and policies are preferable because they more effectively ensure that executives are compensated for superior performance. Other structures and policies are inappropriate and should be prohibited.</p>
							<ul>
								<li>
									<h6>5.10a	Performance Options: </h6>
									<p>Stock options should be: (1) indexed to peer groups or (2) premium-priced and/or (3) vest on achievement of specific performance targets that are based on challenging quantitative goals.</p>
								</li>
								<li>
									<h6>5.10b	Dividend Equivalents: </h6>
									<p>To ensure that executives are neutral between dividends and stock price appreciation, dividend equivalents should be granted with stock options, but distributed only upon exercise of the option.</p>
								</li>
								<li>
									<h6>5.10c	Discount Options: </h6>
									<p>Discount options should not be awarded.</p>
								</li>
								<li>
									<h6>5.10d	Reload Options: </h6>
									<p>Reload options should be prohibited.</p>
								</li>
								<li>
									<h6>5.10e	Option Repricing:</h6>
									<p>"Underwater" options should not be repriced or replaced (either with new options or other equity awards), unless approved by shareowners. Repricing programs, with shareowner approval, should exclude directors and executives, restart vesting periods and mandate value-for-value exchanges in which options are exchanged for a number of equivalently valued options/shares.</p>
								</li>
							</ul>
							<h6 id="511">5.11 Stock Awards/Units:</h6>
							<p>Stock awards/units and similar equity-based vehicles generally grant holders stock based on the attainment of performance goals and/or tenure requirements. These types of awards are more expensive to the company than options, since holders generally are not required to pay to receive the underlying stock, and therefore should be limited in size.</p>
							<p>Stock awards should be linked to the attainment of specified performance goals and in some cases to additional time-vesting requirements. Stock awards should not be payable based solely on the attainment of tenure requirements.</p>
							<h6 id="512">5.12 Perquisites:</h6>
							<p>Stock awards should be linked to the attainment of specified performance goals and in some cases to additional time-vesting requirements. Stock awards should not be payable based solely on the attainment of tenure requirements.</p>
							<h6 id="513">5.13 Employment Contracts, Severance and Change-of-control Payments:</h6>
							<p>Various arrangements may be negotiated to outline terms and conditions for employment and to provide special payments following certain events, such as a termination of employment with/without cause and/or a change in control.          UMU believes that these arrangements should be used on a limited basis.</p>
							<ul>
								<li>
									<h6>5.13a	Employment Contracts: </h6>
									<p>Companies should only provide employment contracts to executives in limited circumstances, such as to provide modest, short-term employment security to a newly hired or recently promoted executive. Such contracts should have a specified termination date (not to exceed three years); contracts should not be "rolling" on an open-ended basis.</p>
								</li>
								<li>
									<h6>5.13b	Severance Payments: </h6>
									<p>Executives should not be entitled to severance payments in the event of termination for poor performance, resignation under pressure or failure to renew an employment contract. Company payments awarded upon death or disability should be limited to compensation already earned or vested.</p>
									<p>In the event of a change in control, companies should not permit automatic accelerated vesting of all equity awards not yet awarded, paid or vested. A board's compensation committee may have discretion to permit full, partial or no accelerated vesting of equity awards not yet awarded, paid or vested. For example, adjustments may be appropriate to account for the actual performance delivered or the proportional amount of time that passed from the beginning of the performance or vesting period to the trigger date. If the board decides to accelerate awards in full, the company should disclose in the relevant public filing a detailed rationale of the decision and how it relates to shareholder value.</p>
								</li>
								<li>
									<h6>5.13c	Change-in-control Payments: </h6>
									<p>Any provisions providing for compensation following a change-in-control event should be "double-triggered." That is, such provisions should stipulate that compensation is payable only: (1) after a control change actually takes place and (2) if a covered executive's job is terminated because of the control change.</p>
								</li>
								<li>
									<h6>5.13d	Transparency: </h6>
									<p>The compensation committee should fully and clearly describe the terms and conditions of employment contracts and any other agreements/arrangements covering the executive oversight group and reasons why the compensation committee believes the agreements are in the best interests of shareowners.</p>
								</li>
								<li>
									<h6>5.13e	Timely Disclosure: </h6>
									<p>New executive employment contracts or amendments to existing contracts should be immediately disclosed in 8-K filings and promptly disclosed in subsequent 10- Qs.</p>
								</li>
								<li>
									<h6>5.13f	Shareowner Ratification: </h6>
									<p>Shareowners should ratify all employment contracts, side letters or other agreements providing for severance, change-in-control or other special payments to executives exceeding 2.99 times average annual salary plus annual bonus for the previous three years.</p>
								</li>
							</ul>
							<h6 id="514">5.14 Retirement Arrangements:</h6>
							<p>Deferred compensation plans, supplemental executive retirement plans, retirement packages and other retirement arrangements for highly paid executives can result in hidden and excessive benefits. Special retirement arrangements-including those structured to permit employees whose compensation exceeds Internal Revenue Service (IRS) limits to fully participate in similar plans covering other employees-should be consistent with programs offered to the general workforce, and they should be reasonable.</p>
							<ul>
								<li>
									<h6>5.14a	Supplemental Executive Retirement Plans (SERPs): </h6>
									<p>Supplemental plans should be an extension of the retirement program covering other employees. They should not include special provisions that are not offered under plans covering other employees, such as above-market interest rates and excess service credits. Payments such as stock and stock options, annual/long- term bonuses and other compensation not awarded to other employees and/or not considered in the determination of retirement benefits payable to other employees should not be considered in calculating benefits payable under SERPs.</p>
								</li>
								<li>
									<h6>5.14b	Deferred Compensation Plans: </h6>
									<p>Investment alternatives offered under deferred compensation plans for executives should mirror those offered to employees in broad-based deferral plans. Above-market returns should not be applied to executive deferrals, nor should executives receive "sweeteners" for deferring cash payments into company stock.</p>
								</li>
								<li>
									<h6>5.14c	Post-retirement Exercise Periods:</h6>
									<p>Executives should be limited to three-year post- retirement exercise periods for stock option grants.</p>
								</li>
								<li>
									<h6>5.14d	Retirement Benefits: </h6>
									<p>Executives should not be entitled to special perquisites-such as apartments, automobiles, use of corporate aircraft, security, financial planning-and other benefits upon retirement. Executives are highly compensated employees who should be more than able to cover the costs of their retirement.</p>
								</li>
							</ul>
							<h6 id="515">5.15 Stock Ownership:</h6>
							<ul className="mb-0">
								<li>
									<h6>5.15a	Ownership Requirements:	</h6>
									<p>Executives and directors should own, after a reasonable period of time, a meaningful position in the company's common stock. Executives should be required to own stock-excluding unexercised options and unvested stock awards-equal to a multiple of salary. The stock subject to the ownership requirements should not be pledged or otherwise encumbered. The multiple should be scaled based on position, for example: two times salary for lower-level executives and up to six times salary for the CEO.</p>
								</li>
								<li>
									<h6>5.15b	Stock Sales: </h6>
									<p>Executives should be required to sell stock through pre-announced 10b5-1 program sales or by providing a minimum 30-day advance notice of any stock sales. 10b5-1 program adoptions, amendments, terminations and transactions should be disclosed immediately, and boards of companies using 10b5-1 plans should: (1) adopt policies covering plan practices, (2) periodically monitor plan transactions and (3) ensure that company policies discuss plan use in the context of guidelines or requirements on equity hedging, holding and ownership.</p>
								</li>
								<li>
									<h6>5.15c	Post-retirement Holdings: </h6>
									<p>Executives should be required to continue to satisfy the minimum stock holding requirements for at least six months after leaving the company.</p>
								</li>
								<li>
									<h6>5.15d	Transparency: </h6>
									<p className="mb-0">Companies should disclose stock ownership requirements and whether any members of the executive oversight group are not in compliance.</p>
								</li>
							</ul>
						</div>
					</div>
					<div id="DirectorCompensation" className="introduction mt-5">
						<h3>6.	Director Compensation</h3>
						<ul>
							<li><a rel="noreferrer" href="#61"><span>6.1</span>  Introduction</a></li>
							<li><a rel="noreferrer" href="#62"><span>6.2</span>  Role of the Compensation Committee in Director Compensation</a></li>
							<li><a rel="noreferrer" href="#63"><span>6.3</span>  Retainer</a></li>
							<li><a rel="noreferrer" href="#64"><span>6.4</span>  Equity-based Compensation</a></li>
							<li><a rel="noreferrer" href="#65"><span>6.5</span>  Performance-based Compensation</a></li>
							<li><a rel="noreferrer" href="#66"><span>6.6</span>  Perquisites</a></li>
							<li><a rel="noreferrer" href="#67"><span>6.7</span>  Repricing and Exchange Programs</a></li>
							<li><a rel="noreferrer" href="#68"><span>6.8</span>  Employment Contracts, Severance and Change-of-control Payments</a></li>
							<li><a rel="noreferrer" href="#69"><span>6.9</span>  Retirement</a></li>
							<li><a rel="noreferrer" href="#610"><span>6.10</span> Disgorgement</a></li>
						</ul>
						<div className="introduction-content annual-election">
							<h6 id="61">6.1 Introduction:</h6>
							<p>Given the vital importance of their responsibilities, non-employee directors should expect to devote significant time to their boardroom duties.</p>
							<p>Policy issues related to director compensation are fundamentally different from executive compensation. Director compensation policies should accomplish the following goals: (1) attract highly qualified candidates,
								(2) retain highly qualified directors, (3) align directors' interests with those of the long-term owners of the corporation and (4) provide complete disclosure to shareowners regarding all components of director compensation including the philosophy behind the program and all forms of compensation.</p>
							<p>To accomplish these goals, director compensation should consist solely of a combination of cash retainer and equity-based compensation. The cornerstone of director compensation programs should be alignment of interests through the attainment of significant equity holdings in the company meaningful to each individual director.  The  UMU  believes  that  equity  obtained  with  an  individual's  own  capital  provides    the                best alignment of interests with other shareowners. However, compensation plans can provide supplemental means of obtaining long-term equity holdings through equity compensation, long-term holding requirements and ownership requirements.</p>
							<p>Companies should have flexibility within certain broad policy parameters to design and implement director compensation plans that suit their unique circumstances. To support this flexibility, investors must have complete and clear disclosure of both the philosophy behind the compensation plan as well as the actual compensation awarded under the plan. Without full disclosure, it is difficult to earn investors' confidence and support for director and executive compensation plans.</p>
							<p>Although non-employee director compensation is generally immaterial to a company's bottom line and small relative to executive pay, director compensation is an important piece of a company's governance. Because director pay is set by the board and has inherent conflicts of interest, care must be taken to ensure there is no appearance of impropriety. Companies should pay particular attention to managing these conflicts.</p>
							<h6 id="62">6.2 Role of the Compensation Committee in Director Compensation</h6>
							<p>The compensation committee (or alternative committee comprised solely of independent directors) is responsible for structuring director pay, subject to approval of all the independent directors, so that it is aligned with the long-term interests of shareowners. Because directors set their own compensation, the following practices should be emphasized:</p>
							<ul>
								<li>
									<h6>6.2a	Total Compensation Review: </h6>
									<p>The compensation committee should understand and value each component of director compensation and annually review total compensation potentially payable to each director.</p>
								</li>
								<li>
									<h6>6.2b	Outside Advice: </h6>
									<p>Committees should have the ability to hire a compensation consultant for assistance on director compensation plans. In cases where the compensation committee does use a consultant, it should always retain an independent compensation consultant or other advisers it deems appropriate to assist with the evaluation of the structure and value of director compensation. A summary of the pay consultant's advice should be provided in the annual proxy statement in plain English. The compensation committee should disclose all instances where the consultant is also retained by the committee to provide advice on executive compensation.</p>
								</li>
								<li>
									<h6>6.2c	Compensation Committee Report: </h6>
									<p>The annual director compensation disclosure included in the proxy materials should include a discussion of the philosophy for director pay and the processes for setting director pay levels. Reasons for changes in director pay programs should be explained in plain English. Peer group(s) used to compare director pay packages should be fully disclosed, along with differences, if any, from the peer group(s) used for executive pay purposes. While peer analysis can be valuable, peer-relative justification should not dominate the rationale for (higher) pay levels. Rather, compensation programs should be appropriate for the circumstances of the company. The report should disclose how many committee meetings involved discussions of director pay.</p>
								</li>
							</ul>
							<h6 id="63">6.3 Retainer:</h6>
							<ul>
								<li>
									<h6>6.3a	Amount of Annual Retainer: </h6>
									<p>The annual retainer should be the sole form of cash compensation paid to non-employee directors. Ideally, it should reflect an amount appropriate for a director's expected duties, including attending meetings, preparing for meetings/discussions and performing due diligence on sites/operations (which should include routine communications with a broad group of employees). In some combination, the retainer and the equity component also reflect the director's contribution from experience and leadership. Retainer amounts may be differentiated to recognize that certain non-employee directors-possibly including independent board chairs, independent lead directors, committee chairs or members of certain committees-are expected to spend more time on board duties than other directors.</p>
								</li>
								<li>
									<h6>6.3b	Meeting Attendance Fees: </h6>
									<p>Directors should not receive any meeting attendance fees since attending meetings is the most basic duty of a non-employee director.</p>
								</li>
								<li>
									<h6>6.3c	Director Attendance Policy:</h6>
									<p>The board should have a clearly defined attendance policy. If the committee imposes financial consequences (loss of a portion of the retainer or equity) for missing meetings as part of the director compensation program, this should be fully disclosed.
										Financial consequences for poor attendance, while perhaps appropriate in some circumstances, should not be considered in lieu of examining the attendance record, commitment (time spent on director duties) and contribution in any review of director performance and in re-nomination decisions.
									</p>
								</li>
							</ul>
							<h6 id="64">6.4 Equity-based Compensation:</h6>
							<p>Equity-based compensation can be an important component of director compensation. These tools are perhaps best suited to instill optimal long-term perspective and alignment of interests with shareowners. To accomplish this objective, director compensation should contain an ownership requirement or incentive and minimum holding period requirements.</p>
							<ul>
								<li>
									<h6>6.4a	Vesting of Equity-based Awards: </h6>
									<p>To complement the annual retainer and align director- shareowner interests, non-employee directors should receive stock awards or stock-related awards such as phantom stock or share units. Equity-based compensation to non-employee directors should be fully vested on the grant date. This point is a marked difference to UMU's policy on executive compensation, which calls for performance-based vesting of equity-based awards. While views on this topic are mixed, UMU believes that the benefits of immediate vesting outweigh the complications. The main benefits are the immediate alignment of interests with shareowners and the fostering of independence and objectivity for the director.</p>
								</li>
								<li>
									<h6>6.4b	Ownership Requirements: </h6>
									<p>Ownership requirements should be at least three to five times annual compensation. However, some qualified director candidates may not have financial means to meet immediate ownership thresholds. For this reason, companies may set either a minimum threshold for ownership or offer an incentive to build ownership. This concept should be an integral component of the committee's disclosure related to the philosophy of director pay. It is appropriate to provide a reasonable period of time for directors to meet ownership requirements or guidelines.</p>
								</li>
								<li>
									<h6>6.4c	Holding Periods:</h6>
									<p>Separate from ownership requirements, UMU believes companies should adopt holding requirements for a significant majority of equity-based grants. Directors should be required to retain a significant portion (such as 80 percent) of equity grants until after they retire from the board. These policies should also prohibit the use of any transactions or arrangements that mitigate the risk or benefit of ownership to the director. Such transactions and arrangements inhibit the alignment of interests that equity compensation and ownership requirements provide.</p>
								</li>
								<li>
									<h6>6.4d	Mix of Cash and Equity-based Compensation: </h6>
									<p>Companies should have the flexibility to set and adjust the split between equity-based and cash compensation as appropriate for their circumstances. The rationale for the ratio used is an important element of disclosures related to the overall philosophy of director compensation and should be disclosed.</p>
								</li>
								<li>
									<h6>6.4e	Transparency:</h6>
									<p>The present value of equity awards paid to each director during the previous year and the philosophy and process used in determining director pay should be fully disclosed in the proxy statement.</p>
								</li>
								<li>
									<h6>6.4f	Shareowner Approval: </h6>
									<p>Current listing standards require shareowner approval of equity- based compensation plans and material amendments to plans (with limited exceptions).
										Companies should adopt conservative interpretations of approval requirements when confronted with choices.
									</p>
								</li>
							</ul>
							<h6 id="65">6.5 Performance-based Compensation:</h6>
							<p>While UMU is a strong advocate of performance- based concepts in executive compensation, we do not support performance measures in director compensation. Performance-based compensation for directors creates potential conflicts with the director's primary role as an independent representative of shareowners.</p>
							<h6 id="66">6.6 Perquisites:</h6>
							<p>Directors should not receive perquisites other than those that are meeting-related, such as air-fare, hotel accommodations and modest travel/accident insurance. Health, life and other forms of insurance; matching grants to charities; financial planning; automobile allowances and other similar perquisites cross the line as benefits offered to employees. Charitable awards programs are an unnecessary
								benefit; directors interested in posthumous donations can do so on their own via estate planning. Infrequent token gifts of modest value are not considered perquisites.
							</p>
							<h6 id="67">6.7 Repricing and Exchange Programs:</h6>
							<p>Under no circumstances should directors participate in or be eligible for repricing or exchange programs.</p>
							<h6 id="68">6.8 Employment Contracts, Severance and Change-of-control Payments:</h6>
							<p>Non-employee directors should not be eligible to receive any change-in-control payments or severance arrangements.</p>
							<h6 id="69">6.9 Retirement:</h6>
							<ul>
								<li>
									<h6>6.9a	Retirement Benefits: </h6>
									<p>Since non-employee directors are elected representatives of shareowners and not company employees, they should not be offered retirement benefits, such as defined benefit plans or deferred stock awards, nor should they be entitled to special post- retirement perquisites.</p>
								</li>
								<li>
									<h6>6.9b	Deferred Compensation Plans: </h6>
									<p>Directors may defer cash pay via a deferred compensation plan for directors. However, such investment alternatives offered under deferred compensation plans for directors should mirror those offered to employees in broad-based deferral plans. Non-employee directors should not receive "sweeteners" for deferring cash payments into company stock.</p>
								</li>
							</ul>
							<h6 id="610">6.10 Disgorgement:</h6>
							<p className="mb-0">Directors should be required to repay compensation to the company in the event of malfeasance or a breach of fiduciary duty involving the director.</p>
						</div>
					</div>
					<div id="IndependentDirector" className="introduction my-5">
						<h3>7.	Independent Director Definition</h3>
						<ul>
							<li><a rel="noreferrer" href="#71"><span>7.1</span>  Introduction</a></li>
							<li><a rel="noreferrer" href="#72"><span>7.2</span>  Basic Definition of an Independent Director</a></li>
							<li><a rel="noreferrer" href="#73"><span>7.3</span>  Guidelines for Assessing Director Independence</a></li>
						</ul>
						<div className="introduction-content annual-election">
							<h6 id="71">7.1 Introduction:</h6>
							<p>A narrowly drawn definition of an independent director (coupled with a policy specifying that at least two-thirds of board members and all members of the audit, compensation and nominating committees should meet this standard) is in the corporation's and shareowners' financial interest because:</p>
							<ul className="p-0">
								<li>
									<ul>
										<li>
											<p>Independence is critical to a properly functioning board;</p>
										</li>
										<li>
											<p>Certain clearly definable relationships pose a threat to a director's unqualified independence;</p>
										</li>
										<li>
											<p>The effect of a conflict of interest on an individual director is likely to be almost impossible to detect, either by shareowners or other board members; and</p>
										</li>
										<li>
											<p>While an across-the-board application of any definition to a large number of people will inevitably miscategorize a few of them, this risk is sufficiently small and is far outweighed by the significant benefits.</p>
										</li>
									</ul>
								</li>
							</ul>
							<p>Independent directors do not invariably share a single set of qualities that are not shared by non- independent directors. Consequently no clear rule can unerringly describe and distinguish independent directors. However, the independence of the director depends on all relationships the director has, including relationships between directors, that may compromise the director's objectivity and loyalty to shareowners.</p>
							<p>Boards have an obligation to consider all relevant facts and circumstances to determine whether a director should be considered independent. These considerations include the director's years of service on the board. Extended periods of service may adversely impact a director's ability to bring an objective perspective to the boardroom.</p>
							<h6 id="72">7.2 Basic Definition of an Independent Director:</h6>
							<p>An independent director is someone whose only nontrivial professional, familial or financial connection to the corporation, its chairman, CEO or any other executive officer is his or her directorship. Stated most simply, an independent director is a person whose directorship constitutes his or her only connection to the corporation.</p>
							<h6 id="73">7.3 Guidelines for Assessing Director Independence:</h6>
							<p>The notes that follow are supplied to give added clarity and guidance in interpreting the specified relationships. A director will not be considered independent if he or she:</p>
							<ul>
								<li>
									<h6>7.3a</h6>
									<p>Is, or in the past five years has been, or whose relative is, or in the past five years has been, employed by the corporation or employed by or a director of an affiliate;</p>
									<p>NOTES: An "affiliate" relationship is established if one entity either alone or pursuant to an arrangement with one or more other persons, owns or has the power to vote more than 20 percent of the equity interest in another, unless some other person, either alone or pursuant to an arrangement with one or more other persons, owns or has the power to vote a greater percentage of the equity interest. For these purposes, joint venture partners and general partners meet the definition of an affiliate, and officers and employees of joint venture enterprises and general partners are considered affiliated. A subsidiary is an affiliate if it is at least 20 percent owned by the corporation.</p>
									<p>Affiliates include predecessor companies. A "predecessor" is an entity that within the last five years was party to a "merger of equals" with the corporation or represented more than 50 percent of the corporation's sales or assets when such predecessor became part of the corporation.</p>
									<p>"Relatives" include spouses, parents, children, step-children, siblings, mothers and fathers-in-law, sons and daughters-in-law, brothers and sisters-in-law, aunts, uncles, nieces, nephews and first cousins, and anyone sharing the director's home.</p>
								</li>
								<li>
									<h6>7.3b</h6>
									<p>Is, or in the past five years has been, or whose relative is, or in the past five years has been, an employee, director or greater-than-20-percent owner of a firm that is one of the corporation's or its affiliate's paid advisers or consultants or that receives revenue of at least
										$50,000 for being a paid adviser or consultant to an executive officer of the corporation;</p>
									<p>NOTES: Advisers or consultants include, but are not limited to, law firms, auditors, accountants, insurance companies and commercial/investment banks. For purposes of this definition, an individual serving "of counsel" to a firm will be considered an employee of that firm.</p>
									<p>The term "executive officer" includes the chief executive, operating, financial, legal and accounting officers of a company. This includes the president, treasurer, secretary, controller and any vice- president who is in charge of a principal business unit, division or function (such as sales, administration or finance) or performs a major policymaking function for the corporation.</p>
								</li>
								<li>
									<h6>7.3c</h6>
									<p>Is, or in the past five years has been, or whose relative is, or in the past five years has been, employed by or has had a five percent or greater ownership interest in a third-party that provides payments to or receives payments from the corporation and either: (i) such payments account for one percent of the third-party's or one percent of the corporation's consolidated gross
										revenues in any single fiscal year; or (ii) if the third-party is a debtor or creditor of the corporation and the amount owed exceeds one percent of the corporation's or third party's assets. Ownership means beneficial or record ownership, not custodial ownership;
									</p>
								</li>
								<li>
									<h6>7.3d</h6>
									<p>Has, or in the past five years has had, or whose relative has paid or received more than
										$50,000 in the past five years under, a personal contract with the corporation, an executive officer or any affiliate of the corporation;</p>
									<p>NOTES: UMU believe that even small personal contracts, no matter how formulated, can threaten a director's complete independence. This includes any arrangement under which the director borrows or lends money to the corporation at rates better (for the director) than those available to normal customers-even if no other services from the director are specified in connection with this relationship;</p>
								</li>
								<li>
									<h6>7.3e</h6>
									<p>Is, or in the past five years has been, or whose relative is, or in the past five years has been, an employee or director of a foundation, university or other non-profit organization that receives significant grants or endowments from the corporation, one of its affiliates or its executive officers or has been a direct beneficiary of any donations to such an organization;</p>
									<p>NOTES: A "significant grant or endowment" is the lesser of $100,000 or one percent of total annual donations received by the organization.</p>
								</li>
								<li>
									<h6>7.3f</h6>
									<p>Is, or in the past five years has been, or whose relative is, or in the past five years has been, part of an interlocking directorate in which the CEO or other employee of the corporation serves on the board of a third-party entity (for-profit or not-for-profit) employing the director or such relative;</p>
								</li>
								<li>
									<h6>7.3g</h6>
									<p>Has a relative who is, or in the past five years has been, an employee, a director or a five percent or greater owner of a third-party entity that is a significant competitor of the corporation; or</p>
								</li>
								<li>
									<h6>7.3h</h6>
									<p>Is a party to a voting trust, agreement or proxy giving his/her decision making power as a director to management except to the extent there is a fully disclosed and narrow voting arrangement such as those which are customary between venture capitalists and management regarding the venture capitalists' board seats.</p>
									<p>The foregoing describes relationships between directors and the corporation. UMU also believes that it is important to discuss relationships between directors on the same board which may threaten either director's independence. A director's objectivity as to the best interests of the shareowners is of utmost importance and connections between directors outside the corporation may threaten such objectivity and promote inappropriate voting blocks. As a result, directors must evaluate all of their relationships with each other to determine whether the director is deemed independent. The board of directors shall investigate and evaluate such relationships using the care, skill, prudence and diligence that a prudent person acting in a like capacity would use.</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Policy;
